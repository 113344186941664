import React from 'react'
import Back from '../commmon/back/Back'
import CoursesCard from './CoursesCard'

const CoursesHome = () => {
  return (
    <>
       <Back title="Explore Courses"/>
       <CoursesCard />
    </>
  )
}

export default CoursesHome