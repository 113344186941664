import React from 'react'
import './team.css'
import { team } from '../../dummydata'

const TeamCard = () => {
  return (
    <>
        {team.map((val) => (
            <div className="items shadow">
                <div className="img">
                    <img src={val.cover} alt="" />
                    <div className="overlay">
                    <a href="https://www.facebook.com/continuumsec"><i className='fab fa-facebook-f icon' href></i></a>
                    <a href="https://www.instagram.com/continuumsecurity/"><i className='fab fa-instagram icon'></i></a>
                    <i className='fab fa-twitter icon'></i>
                    <i className='fab fa-youtube icon'></i>
                    <i className='fab fa-tiktok icon'></i>
                    </div>
                </div>
                <div className="details">
                    <h2>{val.name}</h2>
                    <p>{val.work}</p>
                </div>
            </div>
        ))}
    </>
  )
}

export default TeamCard